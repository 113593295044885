<script>
import {computed, reactive, toRefs} from 'vue';
import router from "../../../router";
import {useI18n} from "vue-i18n";

export default {
  name: 'DriverStateChanger',
  props: {
    driver: {
      type: Object,
      required: true
    },
    loggedUser: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const { t } = useI18n();

    const data = reactive({
      elForm: null,
      dialogVisible: false,
      reasonRequired: true,
      newStateString: null,
      showDiscount: false,
      newPrice: computed(_ => {
        let discount = parseInt(data.form.discount);
        if (isNaN(discount)) {
          discount = 0;
        }

        let res = props.driver.finalPrice - (props.driver.finalPrice * (discount / 100));
        return toCurrency(res);
      }),
      form: {
        newState: null,
        reason: null,
        discount: null
      }
    });

    function toCurrency(value) {
      return value.toLocaleString(t('info.culture'), {style: 'currency', currency: 'CZK', maximumFractionDigits: 0});
    }

    async function submit() {
      await data.elForm.validate(valid => {
        if (valid) {
          window.helpers.server
              .fetchPost(router.registration.xhr.driver, {
                action: 'changeState',
                guid: props.driver.guid,
                newState: data.form.newState,
                reason: data.form.reason,
                discount: data.form.discount
              })
              .then(_ => {
                data.dialogVisible = false;
              });
        }
      });
    }

    function userHasRole(role) {
      return props.loggedUser.roles.includes(role);
    }

    function open(state, stateString, reasonRequired = true, showDiscount = false) {
      data.form.reason = null;
      data.form.newState = state;
      data.form.discount = showDiscount ? 0 : null;
      data.newStateString = stateString;
      data.reasonRequired = reasonRequired;
      data.dialogVisible = true;
      data.showDiscount = showDiscount && userHasRole('admin');
    }

    return {
      ...toRefs(data),
      submit, open, toCurrency
    }
  }
}
</script>

<template>
  <div v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved' || driver.state === 'not_approved'" class="d-flex flex-column">
    <span>{{ $t('lang.changeStatusTo') }}:</span>
    <el-button-group>
      <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved' || driver.state === 'not_approved'" type="success" @click="open('approved', $t('lang.approved'), false, true)">{{ $t('lang.approved') }}</el-button>
      <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'not_approved'" type="warning" @click="open('pre_approved', $t('lang.preliminarilyApproved'))">{{ $t('lang.preliminarilyApproved') }}</el-button>
      <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved'" type="danger" @click="open('not_approved', $t('lang.notApproved'))">{{ $t('lang.notApproved') }}</el-button>

      <el-popconfirm :title="$t('lang.confirmCancelApplication')" width="300" :confirm-button-text="$t('lang.yes')" :cancel-button-text="$t('lang.no')" @confirm="open('cancelled', $t('lang.canceled'))">
        <template #reference>
          <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved' || driver.state === 'not_approved'" type="danger">{{ $t('lang.canceled') }}</el-button>
        </template>
      </el-popconfirm>

    </el-button-group>
  </div>

  <el-dialog
      v-model="dialogVisible"
      :title="$t('lang.changeStatus')"
      width="500">
    <el-form ref="elForm" :model="form" label-width="100">
      <el-form-item :label="$t('lang.newStatus')" prop="newState">
        <el-input disabled v-model="newStateString"></el-input>
      </el-form-item>

      <el-form-item :label="$t('lang.reason')" prop="reason" :rules="[ { required: reasonRequired, message: $t('lang.pleaseFillInReason') } ]">
        <el-input type="textarea" :rows="5" v-model="form.reason"></el-input>
      </el-form-item>

      <el-form-item v-if="reasonRequired === false">
        <el-alert :title="$t('lang.fillingInReasonNotRequired')" type="warning" show-icon :closable="false"/>
      </el-form-item>

      <el-form-item v-if="showDiscount" prop="discount" :label="$t('lang.discountPercentage')">
        <div class="d-flex flex-column">
          <el-input-number :min="0" :max="99" v-model="form.discount"></el-input-number>
          <div>{{ $t('lang.originalPrice') }}: {{ toCurrency(driver.finalPrice, $t('info.culture')) }}</div>
          <div>{{ $t('lang.newPrice') }}: {{ newPrice }}</div>

          <ul class="list-group list-group-flush">
            <li v-for="item in driver.items" class="list-group-item">
              {{ item.quantity }}x {{ item.label }} ({{ toCurrency(item.totalPrice, $t('info.culture')) }})
            </li>
          </ul>
        </div>
      </el-form-item>

      <el-form-item v-if="driver.startNumber === null">
        <el-alert :title="$t('lang.infoStartNumberNotFilled')" type="info" show-icon :closable="false"/>
      </el-form-item>

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('lang.cancel') }}</el-button>
        <el-button type="primary" @click="submit">
          {{ $t('lang.change') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>