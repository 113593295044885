<script>
import {reactive, toRefs} from 'vue';
import router from "../../router";
import {ElMessageBox} from "element-plus";

export default {
  name: 'CustomMailSender',
  props: {
    to: {
      type: String,
      required: true
    },
    reply: {
      type: String,
      required: true
    },
    subject: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    rows: {
      type: Number,
      default: 7
    },
    type: {
      type: String,
      default: 'other'
    },
    extId: {
      type: Number,
      default: null
    },
  },
  setup(props) {

    const data = reactive({
      dialogVisible: false,
      elForm: null,
      form: {
        to: props.to,
        reply: props.reply,
        subject: props.subject,
        content: props.content,
        type: props.type,
        extId: props.extId
      }
    });

    function open() {
      data.dialogVisible = true;
    }

    async function submit() {
      await data.elForm.validate(valid => {
        if (valid) {
          window.helpers.server
              .fetchPost(router.admin.xhr.mail, data.form)
              .then((message) => {
                ElMessageBox.alert(message);
                data.dialogVisible = false;
              });
        }
      });
    }

    function closed() {
      data.form.subject = props.subject;
      data.form.content = props.content;
    }

    return {
      ...toRefs(data),
      open, submit, closed
    }
  }
}
</script>

<template>
  <slot :onClick="open" name="activator"></slot>
  <el-dialog
      v-model="dialogVisible"
      title="Odeslat e-mail"
      width="700px"
      @closed="closed"
  >
    <el-form ref="elForm" :model="form" label-width="150px">
      <el-form-item :label="$t('lang.to')" prop="to">
        <el-input v-model="form.to" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$t('lang.replyTo')" prop="reply">
        <el-input v-model="form.reply" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$t('lang.subject')" prop="subject" :rules="[ { required: true,  message: $t('lang.fillSubject') } ]">
        <el-input v-model="form.subject"></el-input>
      </el-form-item>
      <el-form-item :label="$t('lang.content')" prop="content" :rules="[ { required: true,  message: $t('lang.fillContent') } ]">
        <el-input type="textarea" :rows="rows" v-model="form.content"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('lang.cancel') }}</el-button>
        <el-button type="primary" @click="submit">
          {{ $t('lang.send') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>