<script>
import {reactive, toRefs} from 'vue';
import router from "../../../router";

export default {
  name: 'DriverMoreInfo',
  props: {
    driver: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const data = reactive({
      elForm: null,
      form: {
        arrival: null,
        carShow: 0,
        carTrailer: 0,
        carVan: 0,
        caravan: 0,
        location: null,
      },
    });

    function validateCars(rule, value, callback) {
      let filled = data.form.carShow > 0 || data.form.carTrailer > 0 || data.form.carVan > 0 || data.form.caravan > 0;

      if (!filled) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    }

    async function submit() {
      await data.elForm.validate(valid => {
        if (valid) {
          window.helpers.server
              .fetchPost(router.registration.xhr.driver, {
                action: 'moreInfoForm',
                guid: props.driver.guid,
                ...data.form
              });
        }
      });
    }

    return {
      ...toRefs(data),
      validateCars, submit
    }
  }
}
</script>

<template>
  <div class="driver-more-info">
    <h2><em class="fal fa-siren-on"></em> {{ $t('lang.formToComplete') }}</h2>
    <el-form ref="elForm" :model="form" label-width="300px">
      <el-form-item class="flex-column flex-md-row" :label="$t('lang.arrivalDate')" prop="arrival" :rules="[ { required: true,  message: $t('lang.fillInWhenYouWillArrive') } ]">
        <el-radio-group v-model="form.arrival">
          <el-radio-button label="first">{{ $t('lang.actionFirstDay') }}</el-radio-button>
          <el-radio-button label="second">{{ $t('lang.actionSecondDay') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-divider content-position="left">{{ $t('lang.howWillYouArrive') }}</el-divider>

      <el-form-item class="flex-column flex-md-row" :label="$t('lang.drivenByItself')" prop="carShow" :rules="[ { required: true, trigger: 'change', message: $t('lang.fillInAtLeastOneMeansOfTransportYouWillArriveWith'), validator: validateCars  } ]">
        <el-input-number v-model="form.carShow" :min="0" :max="1" :step="1"></el-input-number>
      </el-form-item>

      <el-form-item class="flex-column flex-md-row" :label="$t('lang.vehicleWithTrailer')" prop="carTrailer" :rules="[ { required: true, trigger: 'change', message: $t('lang.fillInAtLeastOneMeansOfTransportYouWillArriveWith'), validator: validateCars  } ]">
        <el-input-number v-model="form.carTrailer" :min="0" :max="5" :step="1"></el-input-number>
      </el-form-item>

      <el-form-item class="flex-column flex-md-row" :label="$t('lang.deliveryVan')" prop="carVan" :rules="[ { required: true, trigger: 'change', message: $t('lang.fillInAtLeastOneMeansOfTransportYouWillArriveWith'), validator: validateCars } ]">
        <el-input-number v-model="form.carVan" :min="0" :max="5" :step="1"></el-input-number>
      </el-form-item>

      <el-form-item class="flex-column flex-md-row" :label="$t('lang.caravan')" prop="caravan" :rules="[ { required: true, trigger: 'change', message: $t('lang.fillInAtLeastOneMeansOfTransportYouWillArriveWith'), validator: validateCars } ]">
        <el-input-number v-model="form.caravan" :min="0" :max="5" :step="1"></el-input-number>
      </el-form-item>

      <el-alert
          :closable="false"
          :title="$t('lang.ifCapacityOfTheAreaIsReached')"
          type="info">
      </el-alert>

      <el-divider content-position="left"></el-divider>

      <el-form-item class="flex-column flex-md-row" :label="$t('lang.location')" prop="location" :rules="[ { required: true,  message: $t('lang.fillInLocation') } ]">
        <el-radio-group v-model="form.location" class="d-flex flex-column align-items-start">
          <el-radio label="in">{{ $t('lang.iWillBeCampingInTheArea') }}</el-radio>
          <el-radio label="out">{{ $t('lang.iWillBeSleepingOutsideTheArea') }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">{{ $t('lang.submitForm') }}</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<style lang="scss">
.driver-more-info {

  .el-form-item__label {
    display: block;
    margin-left: 0.5rem;
  }

  @media (min-width: 768px) {
    .el-form-item__label {
      display: inherit;
      margin-left: 0;
    }
  }
}
</style>