<script>
import {onMounted, reactive, toRefs} from 'vue';
import router from "../../../router";
import QRCodeVue3 from "qrcode-vue3";
import PeriodicalUpdater from "../../Updater/PeriodicalUpdater.vue";
import helpers from "../../../helpers";

export default {
  name: 'OrderDetail',
  components: {
    PeriodicalUpdater,
    QRCodeVue3
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const data = reactive({
      order: null,
      router: router
    });

    function load() {
      let xhrData = {action: 'getOrder', guid: props.guid};
      window.helpers.server.fetchPost(router.front.xhr.order, xhrData)
          .then((order) => {
            data.order = order;
          });
    }

    onMounted(_ => {
      load();
    });

    return {
      ...toRefs(data),
      load, dateTime: helpers.dateTime
    }
  }
}
</script>

<template>
  <div class="order-detail" v-if="order !== null">
    <h3>{{ $t('lang.orderN', { number: order.number }) }}</h3>
    <el-descriptions :column="1" border>
      <el-descriptions-item :label="$t('lang.action')">{{ order.action.title }}</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.created')">{{ dateTime.formatDateTime(order.createdAt) }}</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.fullName')">{{ order.fullName }}</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.email')">{{ order.email }}</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.phone')">{{ order.phone }}</el-descriptions-item>
      <el-descriptions-item v-if="order.company" :label="$t('lang.company')">{{ order.company }}</el-descriptions-item>
      <el-descriptions-item v-if="order.ico" :label="$t('lang.companyId')">{{ order.ico }}</el-descriptions-item>
      <el-descriptions-item v-if="order.dic" :label="$t('lang.vatNumber')">{{ order.dic }}</el-descriptions-item>
      <el-descriptions-item v-for="item in order.items" :key="item.id">{{ item.quantity }}x {{ item.label }}</el-descriptions-item>

      <el-descriptions-item :label="$t('lang.totalPrice')">{{ order.price }} {{ $t('info.czk') }}</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.state')">
        <span class="d-flex align-items-center gap-1">
        <template v-if="order.state === 'waitingforpayment'"><periodical-updater :loading-func="load" reload-ms="15000" auto-start></periodical-updater></template>
        <span>{{ order.stateString }}</span>
        </span>
      </el-descriptions-item>
      <el-descriptions-item v-if="order.isPaid" label=""><a :href="router.front.ticket.pdfTicket(order.guid, false)"><el-button type="primary">{{ $t('lang.showticketsPlusInvoicePdf') }}</el-button></a></el-descriptions-item>
      <el-descriptions-item v-if="order.isPaid" label=""><a :href="router.front.ticket.pdfTicket(order.guid, true)"><el-button type="success">{{ $t('lang.downloadticketsPlusInvoicePdf') }}</el-button></a></el-descriptions-item>
    </el-descriptions>

    <div v-if="order.state === 'waitingforpayment'" class="mt-3">
      <h3>{{ $t('lang.remainingToPay') }} {{ order.toPay }} {{ $t('info.czk') }}</h3>
      <p>{{ $t('lang.pleaseMakePaymentTo') }}:</p>
      <el-descriptions :column="1" border>
        <el-descriptions-item :label="$t('lang.account')">{{ order.payAccount }}</el-descriptions-item>
        <el-descriptions-item :label="$t('lang.bankCode')">{{ order.payCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('lang.variableSymbol')">{{ order.number }}</el-descriptions-item>
        <el-descriptions-item :label="$t('lang.useIbanBic')"></el-descriptions-item>
        <el-descriptions-item :label="$t('lang.iban')">{{ order.payIban }}</el-descriptions-item>
        <el-descriptions-item :label="$t('lang.bic')">{{ order.payBic }}</el-descriptions-item>
      </el-descriptions>

      <p class="mt-3">{{ $t('lang.orSendPaymentByScanningQRCodeInYourBankingApp') }}:</p>
      <div class="qr-code-img">
        <QRCodeVue3
            :key="order.qrCodeData"
            :value="order.qrCodeData"
            :cornersSquareOptions="{ type: 'square'}"
            :dotsOptions="{ type: 'square' }"
        />
      </div>

      <h4 class="mt-3 border-bottom border-success">
        <span class="fa-stack small text-success mb-1">
          <em class="far fa-circle fa-stack-2x"></em>
          <em class="fa-solid fa-info fa-stack-1x"></em>
        </span>
        {{ $t('lang.uponReceivingTotalAmountToOurAccountYouWillReceiveAnEmailWithTicketsAndInvoice') }}</h4>
    </div>


  </div>
</template>

<style>
.order-detail {
  .el-descriptions__label, .el-descriptions__content, .el-descriptions__cell, .el-button {
    font-size: 1rem !important;
  }

  .el-descriptions__label {
    font-weight: normal !important;
  }

  margin-bottom: 5rem;
}
</style>

<style scoped>
.qr-code-img {
  display: flex;
  justify-content: center;
}
</style>