<script>
import {reactive, toRefs} from 'vue';

export default {
  name: 'StateTimeline',
  props: {
    actualState: {
      type: String,
      required: true
    },
  },
  setup() {
    const data = reactive({
      count: 0,
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div class="state-timeline">
    <el-timeline>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'init' }">
        {{ $t('lang.fillFormCreateRegistration') }}
      </el-timeline-item>
      <el-timeline-item
          :class="{ 'fw-bold':actualState === 'waiting_approve' }"
          :timestamp="$t('lang.preliminaryAcceptanceExplanation')">
        {{ $t('lang.weWillReviewTheApplicationAndDecideIfItWillBeAcceptedRejectedOrPreliminarilyAccepted') }}
      </el-timeline-item>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_for_payment_1' }">
        {{ $t('lang.ifApplicationIsAcceptedWeWillGeneratePaymentDetails') }}
      </el-timeline-item>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_user_finish' }">
        {{ $t('lang.afterReceivingPaymentWeWillSendConfirmationOfApplicationAcceptanceAndAFormWithAdditionalDetailsWillBeAwaitingYou') }}
      </el-timeline-item>
<!--      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_approver_finish' }">-->
<!--        Ten opět zkontrolujeme a případně vás požádáme o doplnění-->
<!--      </el-timeline-item>-->
      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_for_payment_2' }">
        {{ $t('lang.ifAdditionalDetailsRequireFurtherPaymentWeWillGeneratePaymentDetailsAndAwaitPayment') }}
      </el-timeline-item>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'finished' }">
        {{ $t('lang.afterReceivingPaymentWeWillSendConfirmationOfApplicationCompletionAndLookForwardToYourParticipation') }}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<style>
.state-timeline {

  .el-timeline-item__content {
    font-size: 1.3rem !important;
  }


  .el-timeline li {
    margin-bottom: 0;
  }

}
</style>