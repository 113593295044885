<template>
  <div class="animated-items" :style="[ `height:${height}px` ]">
    <ul class="list-container list-group list-group-flush" ref="listContainer">
      <li v-for="(item, index) in items.reverse()" :key="index" class="list-item list-group-item">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AnimatedItems",
  props: {
    items: {
      type: Array,
      required: true
    },
    showInMs: {
      type: Number,
      default: 5000
    },
    height: {
      type: Number,
      default: 150
    },
    moveItems: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      translateY: 0
    }
  },
  methods: {
    start() {
      this.listContainerHeight = this.$refs.listContainer.clientHeight;

      setInterval(() => {
        this.translateY -= 38*this.moveItems;
        if (this.translateY < -this.listContainerHeight) {
          this.translateY = 0;
        }
        this.$refs.listContainer.style.transform = `translateY(${this.translateY}px)`;
      }, this.showInMs);
    }
  },
  mounted() {
    this.start();
  }
}
</script>

<style scoped>
.animated-items {
  overflow: hidden;
}

.list-container {
  transition: transform 0.5s cubic-bezier(0.17, -0.71, 0.43, 1.48);
}

.list-item {
  line-height: 21px;
  background-color: inherit;
  text-wrap: nowrap;
}
</style>


