<script>
import {computed, reactive, toRefs} from 'vue';
import AnimatedItems from "../../Animated/AnimatedItems.vue";

export default {
  name: 'ReservationsViewer',
  components: {AnimatedItems},
  props: {
    reservations: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const data = reactive({
      count: computed(() => {
        return props.reservations.reduce((total, reservation) => {
          return total + reservation.count;
        }, 0);
      }),
      items: computed(() => {
        return props.reservations.map(reservation => {
          return `${reservation.car}`;
        });
      })
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
<h5>{{ $t('lang.confirmedParticipants') }}: {{ count }}</h5>
  <animated-items :items="items"
                  :show-in-ms="2000"
                  :animate-timeout="300">
  </animated-items>
</template>

<style scoped>

</style>