<script>
import {reactive, toRefs} from 'vue';
import { GB, CZ } from 'country-flag-icons/string/3x2';

export default {
  props: {
    locale: {
      type: String,
      required: true,
    }
  },
  name: 'CountryFlag',
  setup() {
    const data = reactive({
      flags: {
        //base64
        en: btoa(GB),
        cs: btoa(CZ),
      }
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <img :src='"data:image/svg+xml;base64," + flags[locale]' :alt="locale + ' flag'" class="img-flag" />
</template>

<style scoped>

.img-flag {
  height: 24px;
}
</style>