import {ScrollSpy} from "bootstrap";

function navbarShrink() {
	const navbarCollapsible = document.body.querySelector('#mainNav');
	const navbarLogo = document.body.querySelector('#mainNav .navbar-brand');

	if (!navbarCollapsible) {
		return;
	}

	if (navbarLogo) {
		navbarLogo.classList.add('fade');
	}

	if (window.scrollY < 100) {
		navbarCollapsible.classList.remove('navbar-shrink');

		if (navbarLogo) {
			navbarLogo.style.opacity = '0';
		}
	} else {
		navbarCollapsible.classList.add('navbar-shrink');
		if (navbarLogo) {
			navbarLogo.style.opacity = '1';
		}
	}
}

function scrollSpy() {
	// Activate Bootstrap scrollspy on the main nav element
	const mainNav = document.body.querySelector('#mainNav');
	let app = document.body.querySelector('#app');
	if (mainNav) {
		new ScrollSpy(app, {
			target: '#mainNav',
			offset: 74,
		});
	}
}

function navbarToggler() {
	// Skryti menu pokud se klikne na polozku a je zobrazeno na mobilu (tzn. je zobrazeno menu tlačítko)
	const navbarToggler = document.body.querySelector('.navbar-toggler');
	const responsiveNavItems = [].slice.call(
		document.querySelectorAll('#navbarResponsive .nav-link')
	);
	responsiveNavItems.map(function (responsiveNavItem) {
		responsiveNavItem.addEventListener('click', () => {
			if (window.getComputedStyle(navbarToggler).display !== 'none') {
				navbarToggler.click();
			}
		});
	});
}

class Web {
	static initOnLoad() {
		navbarShrink();
		document.addEventListener('scroll', navbarShrink);

		scrollSpy();
		navbarToggler();

		const hash = window.location.hash;
		if (hash) {
			const element = document.querySelector(hash);
			if (element) {
				element.scrollIntoView();
			}
		}
	}
}

export default Web;

