<template>
  <div class="click-to-edit d-flex gap-1 flex-column" :class="{ 'editable':!disableEdit && !edit, 'mb-1': edit }">
    <el-input v-if="edit && type === 'text'" v-model="valueLocal"></el-input>
    <el-input-number v-if="edit && type === 'number'" v-model="valueLocal"></el-input-number>
    <el-input v-if="edit && type === 'textarea'" type="textarea" :rows="5" v-model="valueLocal"></el-input>
    <el-date-picker
        v-if="edit && type === 'datePicker'"
        :format="$t('info.luxonDateFormat')"
        v-model="valueLocal"
        type="date"
        value-format="YYYY-MM-DD"
    ></el-date-picker>
    <el-radio-group v-if="edit && type === 'radiobuttons'" v-model="valueLocal" class="d-flex flex-column align-items-start">
      <el-radio v-for="item in items" :key="item[0]" :label="item[0]">{{ item[1] }}</el-radio>
    </el-radio-group>


    <div>
      <el-button v-if="edit" type="success" @click="save">{{ $t('lang.save') }}</el-button>
      <el-button v-if="edit" type="primary" @click="edit = false; valueLocal = modelValue;">{{ $t('lang.cancel') }}</el-button>
    </div>

    <span v-if="edit === false" @click="edit = disableEdit === false">
      <span v-html="nl2br(valueLocal)"></span>
      <template v-if="valueLocal === null">
        <el-button v-if="disableEdit === false" type="primary" size="small">{{ $t('lang.change') }}</el-button>
        <span v-else>{{ $t('lang.notFilled') }}</span>
      </template>
    </span>
  </div>
  <el-popconfirm
      class="ms-2"
      v-if="showDeleteButton && valueLocal !== null && edit === false && disableEdit === false"
      :title="$t('lang.confirmDelete')"
      @confirm="valueLocal = null; save()"
      width="250">
    <template #reference>
      <el-button size="small" type="danger">{{ $t('lang.delete') }}</el-button>
    </template>
  </el-popconfirm>
</template>

<script>
import {DateTime} from "luxon";

export default {
  name: 'ClickToEdit',
  props: {
    modelValue: {
      type: [String, Number, Date],
    },
    type: {
      type: String,
      default: 'text'
    },
    disableEdit: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      required: true
    },
    saveCallable: {
      type: Function,
      required: false
    },
    items: {
      type: Map,
      required: false
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edit: false,
      valueLocal: this.modelValue
    }
  },
  methods: {
    nl2br(value) {
      let res = helpers.filters.nl2br(value);
      if (this.type === 'radiobuttons') {
        return this.items.get(value);
      }
      if (this.type === 'datePicker') {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
      }
      return res;
    },
    async save() {
      let res = await this.saveCallable(this.field, this.valueLocal);
      if (res) {
        this.edit = false;
        this.$emit('update:modelValue', this.valueLocal);
      }
    }
  },

  watch: {
    value: function () {
      this.valueLocal = this.modelValue;
    }
  }

}
</script>

<style scoped>
.click-to-edit {
  &.editable:hover {
    cursor: url("../Images/pencil.svg") 0 64, auto;
  }
}
</style>
