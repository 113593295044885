<script>
import {reactive, toRefs} from 'vue';
import {forEach} from "lodash-es";
import router from "../../router";

export default {
  name: 'RegistrationForm',
  methods: {forEach},
  setup() {
    const data = reactive({
      elForm: null,
      formDisabled: false,
      isSubmitted: false,
      form: {
        email: null,
        car: null,
        count: 1
      },
    });

    function submit() {
      data.elForm.validate((valid) => {
        if (valid) {
          let postData = {
            email: data.form.email,
            car: data.form.car,
            count: data.form.count
          };
          data.formDisabled = true;
          window.helpers.server.fetchPost(router.registration.xhr.attend, postData)
              .then(_ => {
                data.isSubmitted = true;
              });
        }
      });
    }

    return {
      ...toRefs(data),
      submit
    }
  }
}
</script>

<template>
  <div class="form-wrapper p-3 mb-3">
    <template v-if="isSubmitted === false">
      <p class="fw-bold">{{ $t('lang.ifYouPlanToAttendPleaseFillOutThisSimpleForm') }}</p>
      <el-form ref="elForm"
               :disabled="formDisabled"
               :model="form"
               label-width="0"
               @submit.native.prevent="submit">

        <el-form-item label-width="200px" :label="$t('lang.email')" prop="email" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInYourEmail') } ]">
          <el-input v-model="form.email" :placeholder="$t('lang.placeholderEmail')" :maxlength="200" show-word-limit/>
        </el-form-item>

        <el-form-item label-width="200px" :label="$t('lang.theCarYouWillArriveIn')" prop="car" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInTheCarYouWillArriveIn') } ]">
          <el-input v-model="form.car" placeholder="Volvo V70 2007, V90 CC 2023, V60 D6 2015..." :maxlength="200" show-word-limit/>
        </el-form-item>

        <el-form-item label-width="200px" :label="$t('lang.howManyOfYouWillCome')" prop="count" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInHowManyOfYouWillAttend') } ]">
          <el-input-number v-model="form.count" :min="1" :max="10"/>
        </el-form-item>

        <div class="d-flex justify-content-center">
          <el-button type="primary" size="large" native-type="submit" :disabled="buyButtonDisabled">{{ $t('lang.iConfirmMyAttendance') }}</el-button>
        </div>
      </el-form>
    </template>
    <span v-else>
      <el-alert type="success" :closable="false" effect="dark">
        {{ $t('lang.thankYouForConfirmingYourAttendance') }}
      </el-alert>
    </span>
  </div>
</template>

<style scoped>
.form-wrapper {
  background-color: #fecb05;
  color: black;
}
</style>

<style>
.form-wrapper .el-form-item__error {
  font-weight: bold;
  color: red;
}

.form-wrapper .el-form-item__label {
  color: black;
}

.form-wrapper .el-button {
  background-color: #0e2f65;
  border-color: #3d62a0;
}

.form-wrapper .el-alert__description {
  font-size: 1rem;
}
</style>

<style>
.form-wrapper {

  .el-form-item__label {
    font-size: 1rem;
  }

  .el-button--large {
    font-size: 1.2rem;
  }

  .el-form-item__error {
    font-size: 0.8rem;
    top: inherit;
    position: relative;
    background-color: #f56c6c;
    color: white;
    padding: 0.5rem;
  }

  .el-form-item__label {
    display: block;
    margin-left: 0.5rem;
  }

  @media (min-width: 768px) {
    .el-form-item__label {
      display: inherit;
      margin-left: 0;
    }
  }

}
</style>