<script>
import {onBeforeUnmount, onMounted, reactive, toRefs} from 'vue';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
  name: 'GalleryViewer',
  props: {
    galleries: {
      type: Array,
      required: true,
    }
  },
  setup() {
    const data = reactive({
      showDrawer: false,
      selectedGallery: null,
      lightbox: null,
      lightboxIsOpen: false,
    });

    const handleEsc = (event) => {
      if (event.key === 'Escape' && data.lightbox && data.lightboxIsOpen) {
        event.stopPropagation();
        closeLightbox();
      }
    };

    onMounted(() => {
      document.addEventListener('keydown', handleEsc, true);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', eventEsc);
    });

    function openGallery(gallery) {
      data.selectedGallery = gallery;
      data.showDrawer = true;
    }

    function closedDrawer() {
      data.selectedGallery = null;
      destroyLightbox();
    }

    function closeLightbox() {
      if (data.lightbox !== null) {
        data.lightbox.pswp.close();
      }
    }

    function destroyLightbox() {
      if (data.lightbox !== null) {
        data.lightbox.destroy();
        data.lightbox = null;
      }
    }

    function openedDrawer() {
      data.lightbox = new PhotoSwipeLightbox({
        gallery: '#gallery',
        children: 'a',
        pswpModule: () => import('photoswipe'),
        loop: false,
        preload: [1, 4],
      });

      data.lightbox.on('openingAnimationStart', () => {
        data.lightboxIsOpen = true;
      });

      data.lightbox.on('close', () => {
        data.lightboxIsOpen = false;
      });

      data.lightbox.init();
    }

    return {
      ...toRefs(data),
      openGallery, closedDrawer,
      openedDrawer
    }
  }
}
</script>

<template>
  <div class="gallery row">
    <div v-for="(gallery, index) in galleries"
         :key="index"
         class="gallery-preview "
         @click="openGallery(gallery)">
      <div class="image-container">
        <img :src="gallery.previewLink" :alt="$t('lang.imagePreview')">
        <div class="author-overlay">
          <span><em class="fa-light fa-camera-retro"></em> {{ gallery.author }}</span>
          <span><em class="fa-light fa-image"></em> {{ gallery.images.length }}x</span>
        </div>
      </div>
    </div>
  </div>

  <el-drawer
      v-if="selectedGallery !== null"
      v-model="showDrawer"
      :title="selectedGallery.author"
      destroy-on-close
      size="95%"
      @closed="closedDrawer"
      @opened="openedDrawer"
  >

    <div id="gallery" class="masonry">
        <a v-for="(image, index) in selectedGallery.images"
           :key="index"
           :data-pswp-width="image.width"
           :data-pswp-height="image.height"
           target="_blank"
           rel="noopener noreferrer"
           :href="image.link">
          <img :src="image.thumbnail" :alt="image.alt">
        </a>
    </div>
  </el-drawer>
</template>

<style scoped>

.masonry {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.masonry img {
  width: 100%;
  display: block;
  border-radius: 8px;
  object-fit: cover;
  height: auto;
}

.masonry a:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}

.gallery {
  display: flex;
  gap: 20px;
}

.gallery-preview {
  position: relative;
  width: 400px;
  height: 266px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  padding: 0;
}

.gallery-preview:hover {
  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.gallery-preview:hover {
  transform: scale(1.05);
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  text-align: center;
  transition: background 0.3s ease;
  display: flex;
  justify-content: space-evenly;
}

.author-overlay:hover {
  background: rgba(0, 0, 0, 0.8);
}
</style>