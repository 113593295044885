<script>
import {computed, onMounted, reactive, toRefs} from 'vue';
import router from "../../../router";
import DriverPhotos from "./DriverPhotos.vue";
import ClickToEdit from "../../ClickToEdit.vue";
import StateTimeline from "./StateTimeline.vue";
import CustomMailSender from "../../Mail/CustomMailSender.vue";
import DriverStateChanger from "./DriverStateChanger.vue";
import DriverMoreInfo from "./DriverMoreInfo.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: 'DriverDetail',
  components: {DriverMoreInfo, DriverStateChanger, CustomMailSender, StateTimeline, ClickToEdit, DriverPhotos},
  props: {
    guid: {
      type: String,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    loggedUser: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const { t } = useI18n();

    const data = reactive({
      driver: null,
      photos: null,
      campLocationItems: computed(_ => {
        let m = new Map();
        m.set('in', t('lang.iWillBeCampingInTheArea'));
        m.set('out', t('lang.iWillBeSleepingOutsideTheArea'));
        return m;
      }),
      arrivalItems: computed(_ => {
        let m = new Map();
        m.set('first', t('lang.actionFirstDay'));
        m.set('second', t('lang.actionSecondDay'));
        return m;
      }),
      routerRef: computed(_ => {
        return router;
      })
    });

    function load() {
      let postData = {
        action: 'detail',
        guid: props.guid,
      };

      window.helpers.server.fetchPost(router.registration.xhr.driver, postData)
          .then((driver) => {
            data.driver = driver;
          });

      let postDataPhotos = {
        action: 'photos',
        guid: props.guid,
      };

      window.helpers.server.fetchPost(router.registration.xhr.driver, postDataPhotos)
          .then((photos) => {
            data.photos = photos;
          });
    }

    onMounted(() => {
      load();
    });



    async function inlineSave(field, value) {
      let postData = {
        action: 'inlineSave',
        guid: props.guid,
        field: field,
        value: value
      };

      let res = await window.helpers.server.fetchPost(router.registration.xhr.driver, postData);
      return res === 'ok';
    }

    return {
      ...toRefs(data),
      inlineSave
    }
  }
}
</script>

<template>
  <div class="driver-detail">
    <state-timeline v-if="driver !== null && isAdmin === false" :actual-state=driver.state></state-timeline>
    <el-card v-if="driver !== null && isAdmin === false && driver.state === 'waiting_user_finish'" class="mb-4">
      <driver-more-info :driver="driver"></driver-more-info>
    </el-card>

    <el-descriptions v-if="driver !== null" border direction="horizontal" :column="1" class="mb-3">
      <el-descriptions-item :label="$t('lang.registrationStatus')">
        <div class="d-flex flex-column gap-3">
          <span>{{ driver.stateString }}</span>
          <div v-if="driver.state === 'finished'" class="d-flex flex-column gap-2">
            <a :href="routerRef.front.file('prohlaseni-spolujizda-2024', 'pdf')"><el-link type="primary"><em class="fal fa-file-pdf fa-2x me-2"></em> {{ $t('lang.downloadDisclaimer') }}</el-link></a>
            <el-alert :title="$t('lang.thisDocumentNeedsToBePrintedFilledOutAndSubmittedDuringPresentation')" :closable="false" type="warning" show-icon />
          </div>
          <driver-state-changer v-if="isAdmin" :driver="driver" :logged-user="loggedUser"></driver-state-changer>
        </div>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.action')">{{ driver.action.title }}</el-descriptions-item>
      <el-descriptions-item v-if="driver.discount" :label="$t('lang.discount')">{{ driver.discount }}%</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.class')">{{ driver.group.label }}</el-descriptions-item>
      <el-descriptions-item :label="$t('lang.fullName')">
        <click-to-edit v-model="driver.fullName" :save-callable="inlineSave" field="fullName" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.dateOfBirth')">
        <click-to-edit v-model="driver.birthDate" :save-callable="inlineSave" field="birthDate" type="datePicker" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.street')">
        <click-to-edit v-model="driver.street" :save-callable="inlineSave" field="street" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.city')">
        <click-to-edit v-model="driver.city" :save-callable="inlineSave" field="city" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.zipCode')">
        <click-to-edit v-model="driver.zip" :save-callable="inlineSave" field="zip" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.company')">
        <click-to-edit v-model="driver.company" :save-callable="inlineSave" field="company" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.companyId')">
        <click-to-edit v-model="driver.ico" :save-callable="inlineSave" field="ico" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.vatNumber')">
        <click-to-edit v-model="driver.dic" :save-callable="inlineSave" field="dic" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-for="item in driver.items" label="">
        {{ item.quantity }}x {{ item.label }}
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.email')">
        <div class="d-flex flex-column">
          <click-to-edit v-model="driver.email" :save-callable="inlineSave" field="email" :disable-edit="disableEdit"></click-to-edit>
          <custom-mail-sender v-if="isAdmin && loggedUser !== null"
                              :ext-id="driver.id"
                              type="driver"
                              :to="driver.email"
                              :reply="loggedUser.data.email"
                              :subject="$t('lang.registration') + ' ' + driver.fullName + ' | ' + driver.stateString">
            <template v-slot:activator="{ onClick }">
              <el-button-group>
                <el-button @click="onClick" type="primary">{{ $t('lang.sendEmail') }}</el-button>
              </el-button-group>
            </template>
          </custom-mail-sender>
        </div>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.phone')">
        <click-to-edit v-model="driver.phone" :save-callable="inlineSave" field="phone" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.drivingLicenseNumber')">
        <click-to-edit v-model="driver.driverId" :save-callable="inlineSave" field="driverId" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.brand')">
        <click-to-edit v-model="driver.carBrand" :save-callable="inlineSave" field="carBrand" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.model')">
        <click-to-edit v-model="driver.carModel" :save-callable="inlineSave" field="carModel" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.yearOfManufacture')">
        <click-to-edit v-model="driver.carYear" :save-callable="inlineSave" field="carYear" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.engine')">
        <click-to-edit v-model="driver.carEngine" :save-callable="inlineSave" field="carEngine" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.engineVolume')">
        <click-to-edit v-model="driver.carEnginePower" :save-callable="inlineSave" field="carEnginePower" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.transmission')">
        <click-to-edit v-model="driver.carGearbox" :save-callable="inlineSave" field="carGearbox" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.colorType')">
        <click-to-edit v-model="driver.carColorType" :save-callable="inlineSave" type="textarea" field="carColorType" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.startingNumber')">
        <click-to-edit v-model="driver.startNumber" :save-callable="inlineSave" field="startNumber" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.awd')">
        <click-to-edit v-model="driver.carAwd" :save-callable="inlineSave" field="carAwd" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.mileage')">
        <click-to-edit v-model="driver.carMileage" :save-callable="inlineSave" field="carMileage" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.ownVehicle')">
        <click-to-edit v-model="driver.carHowLong" :save-callable="inlineSave" field="carHowLong" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.numberOfOwners')">
        <click-to-edit v-model="driver.carOwnerCount" :save-callable="inlineSave" field="carOwnerCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.estimatedValue')">
        <click-to-edit v-model="driver.carEstimatedValue" :save-callable="inlineSave" type="textarea" field="carEstimatedValue" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.specialModifications')">
        <click-to-edit v-model="driver.carSpecial" :save-callable="inlineSave" type="textarea" field="carSpecial" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item :label="$t('lang.vehicleInteresting')">
        <click-to-edit v-model="driver.carInterest" :save-callable="inlineSave" type="textarea" field="carInterest" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" :label="$t('lang.arrivalDate')">
        <click-to-edit v-model="driver.arrival" :save-callable="inlineSave" type="radiobuttons" field="arrival" :disable-edit="disableEdit"
                       :items="arrivalItems"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" :label="$t('lang.drivenByItself')">
        <click-to-edit v-model="driver.carShowCount" :save-callable="inlineSave" type="number" field="carShowCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" :label="$t('lang.vehicleWithTrailer')">
        <click-to-edit v-model="driver.carTrailerCount" :save-callable="inlineSave" type="number" field="carTrailerCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" :label="$t('lang.deliveryVan')">
        <click-to-edit v-model="driver.carVanCount" :save-callable="inlineSave" type="number" field="carVanCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" :label="$t('lang.caravan')">
        <click-to-edit v-model="driver.caravanCount" :save-callable="inlineSave" type="number" field="caravanCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" :label="$t('lang.location')">
        <click-to-edit v-model="driver.campLocation" :save-callable="inlineSave" type="radiobuttons" field="campLocation" :disable-edit="disableEdit"
                       :items="campLocationItems"></click-to-edit>
      </el-descriptions-item>
    </el-descriptions>

    <div v-if="photos !== null" class="d-flex gap-2">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>{{ $t('lang.photographLeftSide') }}</span>
          </div>
        </template>
        <driver-photos v-if="photos.front.length > 0 || isAdmin" :editable="isAdmin" :form-hash="guid" type="front" :photos="photos.front"></driver-photos>
      </el-card>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>{{ $t('lang.photographLeftSide') }}</span>
          </div>
        </template>
        <driver-photos v-if="photos.back.length > 0 || isAdmin" :editable="isAdmin" :form-hash="guid" type="back" :photos="photos.back"></driver-photos>
      </el-card>
    </div>
  </div>
</template>

<style>
.driver-detail {
  .el-descriptions__label, .el-descriptions__content, .el-descriptions__cell, .el-button {
    font-size: 1rem !important;
  }

  .el-descriptions__label {
    font-weight: normal !important;
  }

  margin-bottom: 5rem;
}
</style>

<style scoped>

</style>