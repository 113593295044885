import {
	i18n,
	ElementPlus,
	createApp, naja, CountryFlag,
	netteForms, DriverDetail, getElementLocale
} from './core.js';



import Web from "./web";
import VueGtag from "vue-gtag";

//COMPONENTS
import OrderDetail from "./Components/Order/Detail/OrderDetail.vue";
import StateTimeline from "./Components/Registrations/Driver/StateTimeline.vue";
import TicketSweDriveForm from "./Forms/Ticket/TicketSweDriveForm.vue";
import RegistrationForm from "./Forms/Registration/RegistrationForm.vue";
import ReservationsViewer from "./Components/Registrations/Reservations/ReservationsViewer.vue";
import GalleryViewer from "./Components/Gallery/GalleryViewer.vue";

import {onMounted} from "vue";

export const app = createApp({
	setup() {
		onMounted(() => {
			window.Nette = netteForms;
			naja.initialize.bind(naja);
			netteForms.initOnLoad();
			Web.initOnLoad();
		});
		return {}
	}
})
	.component(OrderDetail.name, OrderDetail)
	.component(DriverDetail.name, DriverDetail)
	.component(StateTimeline.name, StateTimeline)
	.component(TicketSweDriveForm.name, TicketSweDriveForm)
	.component(RegistrationForm.name, RegistrationForm)
	.component(CountryFlag.name, CountryFlag)
	.component(ReservationsViewer.name, ReservationsViewer)
	.component(GalleryViewer.name, GalleryViewer)
	.use(ElementPlus, {locale: getElementLocale()})
	.use(i18n)
	.use(VueGtag, {
		config: {id: "G-LMB2MCSTJD"}
	})
	.mount('#app');

