<script>
import {onMounted, reactive, toRefs, nextTick} from 'vue';
import 'simplelightbox/dist/simple-lightbox.css';
import SimpleLightbox from "simplelightbox";
import {v4} from "uuid";
import router from "../../../router";
import {ElMessageBox} from "element-plus";
import {useI18n} from "vue-i18n";

export default {
  name: 'DriverPhotos',
  props: {
    photos: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true
    },
    formHash: {
      type: String,
      required: true
    }
  },
  computed: {
    router() {
      return router
    }
  },
  setup(props) {
    const { t } = useI18n();

    const data = reactive({
      guid: '',
      uploadSettings: {
        accept: "image/png, image/gif, image/jpeg",
        limit: 20,
        listType: 'picture-card',
        autoUpload: true
      },
      fileList: [],
      photoDialogVisible: false,
      dialogImageUrl: '',
    });

    function initLightbox() {
      new SimpleLightbox('#' + data.guid + '.gallery a');
    }

    onMounted(_ => {
      data.guid = 'dp-' + v4();
      nextTick(_ => {
        initLightbox();
      });

      data.fileList = props.photos.map(photo => {
        return {
          name: photo.originalName,
          size: photo.filesize,
          uid: photo.id,
          url: photo.link,
          response: photo
        }
      });
    });

    function handlePhotoPreview(uploadFile) {
      data.dialogImageUrl = uploadFile.url;
      data.photoDialogVisible = true;
    }

    function handlePictureRemove(file) {
      window.helpers.server.fetchPost(router.registration.xhr.driverPhotoRemove(props.formHash, file.response.id), {})
    }

    function handleBeforeRemove() {
      return ElMessageBox.confirm(t('lang.confirmRemovePhoto')).then(
          () => true,
          () => false
      );
    }

    return {
      ...toRefs(data),
      handlePhotoPreview,
      handlePictureRemove,
      handleBeforeRemove
    }
  }
}
</script>

<template>
  <div :id="guid" v-if="editable === false" class="gallery mb-3">
    <a v-for="photo in photos" :href="photo.link" class="gallery">
      <img :src="photo.thumbnailLink" :alt="photo.originalName">
    </a>
  </div>

  <template v-if="editable">
    <el-dialog v-model="photoDialogVisible" width="95%">
      <div class="d-flex justify-content-center">
        <img class="img-fluid" :src="dialogImageUrl" :alt="$t('lang.imagePreview')"/>
      </div>
    </el-dialog>

    <el-upload
        :accept="uploadSettings.accept"
        :limit="uploadSettings.limit"
        v-model:file-list="fileList"
        :list-type="uploadSettings.listType"
        :auto-upload="uploadSettings.autoUpload"
        :on-preview="handlePhotoPreview"
        :on-remove="handlePictureRemove"
        :before-remove="handleBeforeRemove"
        :action="router.registration.xhr.driverPhoto(formHash, type)"
    >
      <el-icon class="d-flex flex-column gap-1">
        <em class="fal fa-plus"></em>
        <span class="small">{{ $t('lang.addPhoto') }}</span>
      </el-icon>
    </el-upload>
  </template>
</template>

<style scoped>
.gallery a:hover {
  cursor: url("../../../Images/magnifying-glass.svg"), auto;
}
</style>