<template>
	<div class="periodical-updater">
		<div v-show="mouseOver && itemsLoading === false" class="buttons">
			<el-button :size="buttonSize" @click="isPaused = !isPaused"><em :class="isPaused ? 'fal fa-play me-2' : 'fal fa-pause me-2'"></em> {{ isPaused ? $t('lang.refresh') : $t('lang.pause') }}</el-button>
			<el-button :size="buttonSize" @click="fire(true)"><em class="fal fa-sync me-2"></em> {{ $t('lang.manualUpdate') }}</el-button>
		</div>

		<el-progress v-show="itemsLoading === false && isPaused === false"
		             :percentage="reloadProgressPerc"
		             :show-text="showText"
		             :stroke-width="strokeWidth"
		             :type="type"
		             :width="width"></el-progress>
		<span v-show="itemsLoading || isPaused"><em :class="isPaused ?  pausedClassName : 'fa-sync fa-spin'" class="fal"></em></span>
	</div>
</template>

<script>
import visibility from "vue-visibility-change";

export default {
	name: "PeriodicalUpdater",
	props: {
		reloadMs: {
			type: Number,
			default: 60000
		},
		timeout: {
			type: Number,
			default: 1000
		},
		autoStart: {
			type: Boolean,
			default: false
		},
		mountedLoad: {
			type: Boolean,
			default: false
		},
		mouseOver: {
			type: Boolean,
			default: false
		},
		loadingFunc: {
			type: Function,
			required: true
		},
		width: {
			type: Number,
			default: 22
		},
		strokeWidth: {
			type: Number,
			default: 3
		},
		showText: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: "circle"
		},
		buttonSize: {
			type: String,
			default: "small"
		},
		pausedClass: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			itemsLoading: false,
			isStopped: false,
			reloadProgress: 0,
			isPaused: false
		}
	},
	methods: {
		async fire(forced = false) {
			this.reloadProgress = 0;
			this.itemsLoading = true;
			try {
				await this.loadingFunc(forced);
			} catch (e) {
				console.error(e);
			} finally {
				this.itemsLoading = false;
			}
		},
		async reloadProgressFunc() {
			if (this.isStopped) {
				return;
			}

			if (visibility.hidden() === false && this.isPaused === false) {
				this.reloadProgress += 1000;
				if (this.reloadProgress >= this.reloadMs) {
					await this.fire();
				}
			}
			setTimeout(this.reloadProgressFunc, this.timeout);
		},
		start() {
			this.isStopped = false;
			this.reloadProgressFunc();
		},
		stop() {
			this.isStopped = true;
		}
	},
	mounted() {
		if (this.autoStart) {
			this.start();
		}
		if (this.mountedLoad) {
			this.fire(true);
		}
	},
	destroyed() {
		this.stop();
	},
	computed: {
		reloadProgressPerc() {
			let perc = (this.reloadProgress / this.reloadMs) * 100;
			if (perc > 100) {
				perc = 100;
			} else if (perc < 0) {
				perc = 0;
			}
			return perc;
		},
		pausedClassName() {
			let def = ['fa-pause-circle', 'animate__animated', 'animate__heartBeat', 'animate__infinite'];
			let plus = this.pausedClass ? this.pausedClass.split(' ') : [];
			let arr = def.concat(plus);
			return arr.join(' ');
		}
	}
}
</script>

<style scoped>
.periodical-updater {
	display: inline-flex;
	align-items: center
}

.buttons {
	display: inline-flex;
	margin-right: 10px;
}
</style>